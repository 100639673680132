<template>
  <b-overlay
    :show="$apollo.loading"
    spinner-variant="primary"
  >
    <b-card
      v-if="programs.length < 1"
      class="text-center"
    >
      No programs are currently open for application!
    </b-card>
    <section class="grid-view">
      <b-card
        v-for="(program, i) in programs"
        :key="i"
        :title="program.title"
        class="ecommerce-card"
        img-alt="card img"
        img-top
        no-body
      >
        <div
          class="px-2 py-1 bg-primary"
        >
          <b-card-title class="text-white">
            {{ program.title }}
          </b-card-title>
          <b-card-sub-title>
            <span class="text-light">{{ program.type }}</span>
          </b-card-sub-title>
        </div>
        <b-card-body class="p-2">
          <b-card-text>
            Description: <br> <em>{{ program.description }}</em>
            <hr>
            Begin date: {{ program.begin_date }}
            <br> Capacity: {{ program.capacity }}
            <br> Industry: {{ program.industry }}
          </b-card-text>
        </b-card-body>
        <!-- Product Actions -->
        <div class="item-options text-center">
          <b-link
            :to="`/startup-portal/programs/${program.id}`"
            class="btn btn-wishlist btn-light"
            variant="light"
          >
            <span>More Information</span>
          </b-link>
          <b-link
            :to="`/startup-portal/application/${program.id}/${program.programs_applicationformstables[0].id}`"
            class="btn btn-primary btn-cart"
          >
            <span>Apply Now</span>
          </b-link>
        </div>
      </b-card>
    </section>
  </b-overlay>
</template>

<script>
import {
  BCard, BCardBody, BCardSubTitle, BCardText, BCardTitle, BLink, BOverlay,
} from 'bootstrap-vue'
import gql from 'graphql-tag'
// import store from '@/store'

export default {
  components: {
    BCard,
    BCardText,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BOverlay,
    BLink,
  },
  data() {
    return {
      programs: [],
    }
  },
  apollo: {
    programs: {
      query() {
        const urlPrefix = window.location.host.split('.')[0]
        const subdomain = (urlPrefix.startsWith('localhost') || urlPrefix.toLowerCase() === 'starworks') ? '.*' : urlPrefix
        return gql`
        {
          programs_basicinfo(where: {users_organizationtable: {domain: {_regex: "${subdomain}"}}, programs_applicationformstables: {status: {_eq: "enabled"}, round_number: {_eq: 1}}, type: {_in: ["Pre-incubation Program", "Incubation Program", "Accelerator Program"]}}) {
            begin_date
            capacity
            description
            id
            image
            industry
            title
            type
            programs_applicationformstables(where: {round_number: {_eq: 1}, status: {_eq: "enabled"}}) {
              id
            }
          }
        }`
      },
      update: data => data.programs_basicinfo,
    },
  },
}
</script>

<style lang="scss">
@import "~@core/scss/base/pages/app-ecommerce.scss";
</style>
